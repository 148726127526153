<template>
	<div id="menuPage">
		<div class="menu">
			<div class="icon">
				<img src="../assets/logo3.png" alt="">
				<span class="tag" v-if="token.type == 'ASSISTANT'">供应商端</span>
				<span class="tag" v-if="token.type == 'OFFICIAL'">合规管理端</span>
				<span class="tag" v-if="token.type == 'Doctor'">用户端</span>
			</div>
			<div :class="$route.name== 'meetlist'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'Doctor'"
				@click="handleSelect('meetlist')">
				<i class="el-icon-s-order"></i>
				<span slot="title">预定会议</span>
			</div>
			<div :class="$route.name== 'meethistory'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'Doctor'"
				@click="handleSelect('meethistory')">
				<i class="el-icon-s-comment"></i>
				<span slot="title">历史会议</span>
			</div>
			<div :class="$route.name== 'my'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'Doctor'"
				@click="handleSelect('my')">
				<i class="el-icon-user-solid"></i>
				<span slot="title">我的</span>
			</div>

			<div :class="$route.name== 'project'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'ASSISTANT'"
				@click="handleSelect('project')">
				<i class="el-icon-s-order"></i>
				<span slot="title">系列会管理</span>
			</div>
			<div :class="$route.name== 'singleMeets'? 'menu-item is-active' : 'menu-item'"
				v-if="token.type == 'ASSISTANT'" @click="handleSelect('singleMeets')">
				<i class="el-icon-s-order"></i>
				<span slot="title">单场会管理</span>
			</div>
			<div :class="$route.name== 'doctor'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'ASSISTANT'"
				@click="handleSelect('doctor')">
				<i class="el-icon-s-marketing"></i>
				<span slot="title">我的医生</span>
			</div>
			<div :class="$route.name== 'template'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'ASSISTANT'"
				@click="handleSelect('template')">
				<i class="el-icon-s-marketing"></i>
				<span slot="title">常用模板</span>
			</div>
			<div :class="$route.name== 'option'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'ASSISTANT'"
				@click="handleSelect('option')">
				<i class="el-icon-s-tools"></i>
				<span slot="title">操作</span>
			</div>
			<div :class="$route.name== 'official'? 'menu-item is-active' : 'menu-item'" v-if="token.type == 'OFFICIAL'"
				@click="handleSelect('official')">
				<i class="el-icon-s-marketing"></i>
				<span slot="title">会议审核</span>
			</div>
			<div :class="$route.name== 'officialSingleMeets'? 'menu-item is-active' : 'menu-item'"
				v-if="token.type == 'OFFICIAL'" @click="handleSelect('officialSingleMeets')">
				<i class="el-icon-s-order"></i>
				<span slot="title">单场会管理</span>
			</div>
			<div :class="$route.name== 'offocoalProject'? 'menu-item is-active' : 'menu-item'"
				v-if="token.type == 'OFFICIAL'" @click="handleSelect('offocoalProject')">
				<i class="el-icon-s-order"></i>
				<span slot="title">系列会管理</span>
			</div>
			<div :class="$route.name== 'notifications'? 'menu-item is-active' : 'menu-item'"
				v-if="token.type == 'OFFICIAL'" @click="handleSelect('notifications')">
				<i class="el-icon-info"></i>
				<span slot="title">消息中心</span>
			</div>
			<!-- <div :class="$route.name== 'myAssistant'? 'menu-item is-active' : 'menu-item'"
				v-if="token.type == 'OFFICIAL'" @click="handleSelect('myAssistant')">
				<i class="el-icon-user-solid"></i>
				<span slot="title">我的助教</span>
			</div> -->
			<div class="logOut">
				<el-button type="text" icon="el-icon-switch-button" @click="logOut()">退出登录</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'menuPage',
		components: {},
		data() {
			return {
				token: '',
				active: ''
			}
		},
		created() {
			let token = JSON.parse(localStorage.getItem('token'));
			if (token) {
				this.token = token;
			} else {
				this.$router.push({
					name: 'login'
				})
			}
			this.active = this.$route.name;
		},
		mounted() {},
		methods: {
			logOut() {
				let that = this;
				this.$confirm('此操作将退出当前账号的登录状态, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					that.$router.push({
						name: 'login'
					})
				});
			},

			handleSelect(key) {
				if (this.token.type == 'ASSISTANT') {
					localStorage.removeItem('regToken')
				}

				this.$router.push({
					name: key
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#menuPage {
		display: flex;
		flex-direction: column;
		border-right: 2px solid #eee;

		.menu {
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: center;
			width: 200px;
			height: 100%;
			overflow: hidden;
			border-radius: 10px;
			background-color: #fff;

			.icon {
				padding: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				img {
					display: block;
					max-width: 100%;
					max-height: 100%;
				}

				span {
					margin-top: 10px;
					color: #999;
					font-size: 14px;
					font-weight: bold;
				}
			}

			.logOut {
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: flex-end;

				.el-button {
					margin: 20px;
					color: #7D8592;
				}
			}

			.menu-item {
				height: 46px;
				width: 150px;
				padding: 0 20px;
				margin: 16px 10px;
				line-height: 46px;
				border-radius: 10px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				cursor: pointer;
				transition: all .3s;

				* {
					cursor: pointer;
				}

				i {
					font-size: 16px;
					margin-right: 5px;
				}

				span {
					font-size: 16px;
				}
			}

			.is-active {
				background-color: #ecf5ff;

				* {
					color: #0068FF;
				}


			}
		}
	}
</style>